export default function dropdown() {
    const dropdowns = document.querySelectorAll('.has-dropdown');
    const dropdownCloseButton = document.querySelectorAll('.dropdown-close-button');


    if (dropdown) {
        dropdowns.forEach(dropdown => {
            dropdown.querySelector('.dropdown-button').addEventListener('click', () => {

                if (dropdown.querySelector('.dropdown').classList.contains('hidden')) {
                    dropdown.querySelector('.dropdown').classList.remove('hidden');
                    dropdown.querySelector('.dropdown-button').classList.remove('text-gray-500');
                    dropdown.querySelector('.dropdown-button').classList.add('text-red-700');
                } else {
                    dropdown.querySelector('.dropdown').classList.add('hidden');
                    dropdown.querySelector('.dropdown-button').classList.add('text-gray-500');
                    dropdown.querySelector('.dropdown-button').classList.remove('text-red-700');
                }

            })
        });
    }

    if (dropdownCloseButton) {
        dropdownCloseButton.forEach(button => {
            button.addEventListener('click', function () {
                this.parentNode.parentNode.classList.toggle('hidden');
                this.parentNode.parentNode.parentNode.querySelector('.dropdown-button').classList.add('text-gray-500');
                this.parentNode.parentNode.parentNode.querySelector('.dropdown-button').classList.remove('text-red-700');
            })
        });
    }



}
