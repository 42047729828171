export default function favorites() {
    const favoritesBox = document.querySelector('#favorites-box');
    const addTofavorite = document.querySelectorAll('.js-add-favorite');

    if (addTofavorite) {
        addTofavorite.forEach(addButton => {

            addButton.addEventListener('click', function () {
                window.scrollTo({ top: 0, behavior: 'smooth' });


                const xhr = new XMLHttpRequest();

                xhr.open('GET', '/nlt/quote_add?offerta_id=' + addButton.dataset.promo + '&redirect=0', true);

                xhr.onreadystatechange = function () {

                    if (xhr.readyState == 4 && xhr.status == 200) {

                        const response = xhr.responseText;
                        favoritesBox.innerHTML = response;
                        favoritesBox.classList.remove('hidden');

                        removeElementFromCart();


                    }
                }

                xhr.send();
            })
        });
    }

    function removeElementFromCart() {

        const removeFromCart = document.querySelectorAll('.remove-from-cart');
        if (removeFromCart) {
            removeFromCart.forEach(remove => {
                remove.addEventListener('click', function () {

                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', '/carrello/quote_remove/' + this.dataset.key + '?redirect=0', true);
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState == 4 && xhr.status == 200) {
                            const response = xhr.responseText;
                            favoritesBox.innerHTML = response;
                            removeElementFromCart();

                            const closeCart = document.querySelector('#close-cart');
                            if (closeCart) {
                                closeCart.addEventListener('click', function () {
                                    favoritesBox.classList.add('hidden');
                                })
                            }
                        }
                    }

                    xhr.send();
                    if (closeCart) {
                        closeCart.addEventListener('click', function () {
                            favoritesBox.classList.add('hidden');
                        })
                    }

                })
            });

        }
    }

}