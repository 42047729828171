export default function accordion() {

    const panelHeader = document.querySelectorAll('.panel__header');

    panelHeader.forEach(header => {
        header.addEventListener('click', function () {
            header.parentNode.classList.toggle('panel--expanded');
        })
    });

}